<template>
    <div class="equipment_total_result">
        <!-- <sn-bread :itemName="itemName" :breadlist="breadlist"></sn-bread> -->
        <div class="etr-top">
            <div class="tatolyc-tJ" :id="tatolycTJ"></div>
            <div class="etr-btn-back">
                <!-- <router-link :to="{ name: 'equipment_state'}"> -->
                <el-button size="mini" @click="gotoDetail">返回</el-button>
                <!-- </router-link> -->
            </div>
        </div>
        <div class="etr-bottom scrollbar">
            <div
                class="etr-equipment-single-result"
                v-for="(item, index) in eqCheckList"
                :key="index"
            >
                <div class="etr-th-title" @click="toggleIcon(item)">
                    <div class="etr-singletitle">
                        {{ item.SBNAME }}异常数据接入情况
                    </div>
                    <div class="etr-ibord">
                        <img class="ec-status-icon" :src="yichangIcon" />
                        <span class="ec-special-text"
                            >异常个数：
                            <span class="ec-normal-count">
                                <span class="ec-normal-num">{{
                                    item.abnormalCount
                                }}</span
                                >个</span
                            >
                        </span>
                    </div>
                    <div class="etr-singlecheck1">
                        上次检测时间：{{ item.time }} （全面检测）
                    </div>
                    <div class="etr-togglebutton">
                        <i
                            :class="{
                                expanded: item.isExpanded,
                                'el-icon-caret-bottom': true,
                            }"
                        ></i>
                    </div>
                </div>
                <transition name="fade">
                    <CheckResultStatic
                        v-if="item && item.isExpanded"
                        :resultList="item.CHILDREN"
                        :eqItem="item"
                    ></CheckResultStatic>
                </transition>
            </div>
        </div>
    </div>
</template>
<script>
import CheckResultStatic from "./check_result_static";
import { mapGetters, mapActions } from "vuex";
import * as TYPES from "@store/modules/mutation-type.js";
export default {
    name: "equipment-state",
    data() {
        return {
            eqCheckList: [],
            expanded: "expanded",
            tatolycTJ: Math.random().toString(36).substr(2),
            yichangIcon: require("@image/dataService_management/yichang.png"),
            breadlist: [
                {
                    path: "",
                    name: "数据管理与接入",
                },
                {
                    path: "",
                    name: "运行状态",
                },
            ],
            itemName: "设备运行状态",
        };
    },
    computed: {
        ...mapGetters({
            RunningStatusResult: TYPES.RunningStatusResult,
            TOTALLIST: TYPES.TOTALLIST,
        }),
    },
    methods: {
        ...mapActions(["GetLxResultList"]),
        setSbName(val) {
            let sbName = "";
            switch (val) {
                case "1":
                    sbName = "地下水水质监测站";
                    break;
                case "2":
                    sbName = "裂隙计";
                    break;
                case "4":
                    sbName = "倾斜计";
                    break;
                case "5":
                    sbName = "风速计";
                    break;
                case "6":
                    sbName = "小型气象站";
                    break;
                case "14":
                    sbName = "土壤墒情设备";
                    break;

                // case "11":
                //     sbName = '地表水水位';
                //     break;
                // case "12":
                //     sbName = '地下水水位';
                //     break;
                // case "15":
                //     sbName = '地表水水质';
                //     break;
                // case "16":
                //     sbName = '微环境';
                //     break;
                // case "17":
                //     sbName = '拥挤程度';
                //     break;
            }
            return sbName;
        },
        drawChart() {
            let data = [];
            let legendData = [];
            this.RunningStatusResult.forEach((ele) => {
                let sbName = this.setSbName(ele.SBLX);
                if (sbName) {
                    data.push({
                        value:
                            ele.JRJGLIST[0].JRJG == "正常"
                                ? ele.JRJGLIST[1].COUNT
                                : ele.JRJGLIST[0].COUNT,
                        name: sbName,
                    });
                    legendData.push(sbName);
                }
            });
            let chart = this.$echarts.init(
                document.getElementById(this.tatolycTJ)
            );
            let option = {
                color: [
                    "#32e3ec",
                    "#6df2c5",
                    "#3da0ff",
                    "#c1f488",
                    "#edc552",
                    "#f38c2a",
                    "#ff6254",
                    "#f3592a",
                    "#8952ed",
                    "#f4888d",
                ],
                tooltip: {
                    trigger: "item",
                    formatter: "{a} <br/>{b}: {c} ({d}%)",
                },
                legend: {
                    orient: "vertical",
                    x: "left",
                    // data:['微环境','局部环境','地表水水质','地表水水位','地下水水位','土壤墒情']
                    data: legendData,
                },
                series: [
                    {
                        name: "设备接入异常",
                        type: "pie",
                        radius: [0, "30%"],
                        label: {
                            normal: {
                                position: "center",
                                formatter: function (params) {
                                    return (
                                        params.data.name +
                                        "\n" +
                                        "共计" +
                                        params.data.value +
                                        "项"
                                    );
                                },
                            },
                        },
                        labelLine: {
                            normal: {
                                show: false,
                            },
                        },
                        data: [
                            {
                                value:
                                    this.TOTALLIST.COUNT[0].JRJG == "正常"
                                        ? this.TOTALLIST.COUNT[1].COUNT
                                        : this.TOTALLIST.COUNT[0].COUNT,
                                name: "异常项",
                                label: {
                                    color: "#A8BDE8",
                                    fontSize: 24,
                                    fontWeight: 500,
                                },
                                itemStyle: {
                                    color: "#fff",
                                },
                                tooltip: null,
                            },
                        ],
                    },
                    {
                        name: "设备接入异常",
                        type: "pie",
                        radius: ["50%", "70%"],
                        avoidLabelOverlap: false,
                        label: {
                            normal: {
                                formatter:
                                    "{a|{a}}{abg|}\n{hr|}\n  {b|{b}：}{c}  {per|{d}%}  ",
                                backgroundColor: "#eee",
                                borderColor: "#aaa",
                                borderWidth: 1,
                                borderRadius: 4,
                                // shadowBlur:3,
                                // shadowOffsetX: 2,
                                // shadowOffsetY: 2,
                                // shadowColor: '#999',
                                // padding: [0, 7],
                                rich: {
                                    a: {
                                        color: "#999",
                                        lineHeight: 22,
                                        align: "center",
                                    },
                                    // abg: {
                                    //     backgroundColor: '#333',
                                    //     width: '100%',
                                    //     align: 'right',
                                    //     height: 22,
                                    //     borderRadius: [4, 4, 0, 0]
                                    // },
                                    hr: {
                                        borderColor: "#aaa",
                                        width: "100%",
                                        borderWidth: 0.5,
                                        height: 0,
                                    },
                                    b: {
                                        fontSize: 16,
                                        lineHeight: 33,
                                    },
                                    per: {
                                        color: "#eee",
                                        backgroundColor: "#334455",
                                        padding: [2, 4],
                                        borderRadius: 2,
                                    },
                                },
                            },
                        },
                        labelLine: {
                            normal: {
                                show: true,
                            },
                        },
                        data: data,
                    },
                ],
            };
            chart.setOption(option);
        },
        async toggleIcon(val) {
            let CHILDREN = [];
            let result = await this.GetLxResultList({
                lxid: val.SBLX,
            });
            result.ResultValue.forEach((element) => {
                element.JRJG == "正常" ? "" : CHILDREN.push(element);
            });
            val.CHILDREN = CHILDREN;
            val.isExpanded = !val.isExpanded;
        },
        // 返回
        gotoDetail() {
            this.$emit("gotoDetail", false);
        },
    },
    components: {
        CheckResultStatic,
    },
    created() {
        let _this = this;
        let tempList = [];
        this.RunningStatusResult.forEach((element) => {
            let tempObj = {};
            tempObj.time = element.SCJCSJ;
            tempObj.abnormalCount =
                element.JRJGLIST[0].JRJG == "正常"
                    ? element.JRJGLIST[1].COUNT
                    : element.JRJGLIST[0].COUNT;
            tempObj.isExpanded = false;
            tempObj.checkState = false;
            tempObj.SBLX = element.SBLX;
            tempObj.SBNAME = _this.setSbName(element.SBLX);
            tempList.push(tempObj);
        });
        this.eqCheckList = tempList;
    },
    mounted() {
        this.drawChart();
    },
    watch: {
        RunningStatusResult() {},
    },
};
</script>
<style scoped>
.equipment_total_result {
    width: 100%;
    height: 100%;
}

.etr-top {
    position: relative;
    width: 100%;
    height: 430px;
    margin-top: -1px;
    padding: 25px;
    background: #fff;
    box-shadow: 1px 1px 5px #ccc;
}

.etr-btn-back {
    position: absolute;
    right: 20px;
    top: 20px;
}

.etr-fade {
    position: relative;
}

.etr-bottom {
    width: 100%;
    height: calc(100% - 510px);
    margin-top: 5px;
    overflow-x: hidden;
    overflow-y: auto;
}

.expanded {
    transform: rotate(180deg);
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

/* .fade-leave-active 在低于版本 2.1.8 中 */
.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.etr-equipment-single-result {
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
}

.tatolyc-tJ {
    width: 100%;
    height: 400px;
}

/* 单个设备类型 */

.etr-th-title {
    width: 100%;
    margin: 20px 0 0 0;
    padding: 10px 25px;
    background: #fff;
    box-shadow: 1px 1px 5px #ccc;
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
}

.etr-singletitle {
    width: 30%;
    height: 50px;
    line-height: 50px;
    text-align: left;
    font-size: 22px;
}

.etr-singlecount {
    height: 50px;
    width: 30%;
    border-bottom: 1px solid #ccc;
}

.etr-singlecount i {
    color: #de3923;
}

.etr-singlecheck1 {
    width: calc(40% - 50px);
    height: 50px;
    line-height: 50px;
    text-align: right;
}

.etr-togglebutton {
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: right;
}

.etr-ibord {
    width: 30%;
    height: 50px;
    line-height: 50px;
    text-align: left;
}

.ec-status-icon {
    margin: 0 8px 15px 0;
}

.ec-special-text {
    color: #ff9066;
}

.ec-normal-count {
    color: #333;
}

.ec-normal-num {
    margin: 0 5px 0 10px;
    font-size: 20px;
    font-weight: bold;
}
</style>
