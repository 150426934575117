<template>
    <div class="crs-equipment-check-result">
        <div class="linkIcon">
            <div class="link-left"></div>
            <div class="link-right"></div>
        </div>
        <el-table
            :data="resultList"
            :show-header="true"
            stripe
            :cell-class-name="tableCellClassName"
            style="width: 100%"
            max-height="600"
        >
            <el-table-column prop="SBMC" label="设备名称" width="300">
            </el-table-column>
            <el-table-column prop="JCKSSJ" label="时间" width="180">
            </el-table-column>
            <el-table-column prop="JRJG" label="检测结果" width="100">
                <template slot-scope="scope">
                    {{ scope.row.JRJG == "正常" ? "正常" : "异常" }}
                </template>
            </el-table-column>
            <!-- <el-table-column label="原因" width="300">
                <template slot-scope="scope">
                    <i class='el-icon-warning' style="cursor: pointer;" v-if="scope.row.JRJG != '正常'" title="异常原因" @click="getDetail(scope.row)"></i>
                </template>
            </el-table-column> -->
            <el-table-column
                property="YCLX"
                label="异常类型"
                width="200"
                :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
                property="YCNR "
                label="异常内容"
                :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column
                property="JYFA"
                label="建议方案"
                :show-overflow-tooltip="true"
            ></el-table-column>
            <el-table-column label="异常负责人">
                <template slot-scope="scope">
                    {{ scope.row.FZR }}
                </template>
            </el-table-column>
        </el-table>
        <!-- <div class="er-bottom">
            <div class="er-ibord">
                <img class="er-status-icon" :src="zhengchangIcon" />
                <span class="er-normal-text">正常个数：
                    <span class="er-normal-count">
                        <span class="er-normal-num">{{eqItem.NormalCount}}</span>个</span>
                </span>
            </div>
            <div class="er-ibord">
                <img class="er-status-icon" :src="yichangIcon" />
                <span class="er-special-text">异常个数：
                    <span class="er-normal-count">
                        <span class="er-normal-num">{{eqItem.abnormalCount}}</span>个</span>
                </span>
            </div>
            <span class="er-lasttime">
                上次检测时间：{{eqItem.SCJCSJ}}
            </span>
        </div> -->
        <!-- <el-dialog title="异常原因及建议" :visible.sync="dialogTableVisible">
            <el-table :data="gridData">
                <el-table-column property="YCLX" label="异常类型" width="180" :show-overflow-tooltip='true'></el-table-column>
                <el-table-column property="YCNR " label="异常内容" :show-overflow-tooltip='true'></el-table-column>
                <el-table-column property="JYFA" label="建议方案" :show-overflow-tooltip='true'></el-table-column>
            </el-table>
        </el-dialog> -->
        <div class="etr-arrows"></div>
    </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
    name: "equipment-state",
    data() {
        return {
            buttonType: "primary",
            checkState: false,
            checkResult: {
                finishedCount: 2,
                NormalCount: 2,
                abnormalCount: 0,
                time: "2017-12-31 16:54:12",
            },
            word: "检测",
            isExpanded: false,
            expanded: "expanded",
            activeNames: [],
            gridData: [],
            dialogTableVisible: false,
            zhengchangIcon: require("@image/dataService_management/zhengchang.png"),
            yichangIcon: require("@image/dataService_management/yichang.png"),
        };
    },
    props: {
        resultList: {
            type: Array,
        },
        eqItem: {
            type: Object,
        },
    },
    methods: {
        ...mapActions(["GetCheckTypeDetail"]),
        handleChange() {
            this.checkState = !this.checkState;
        },
        toggleIcon() {
            this.isExpanded = !this.isExpanded;
        },
        // eslint-disable-next-line no-unused-vars
        tableCellClassName({ row, column, rowIndex, columnIndex }) {
            if (columnIndex == 0) {
                if (row.JRJG == "正常") {
                    return "success-row";
                } else {
                    return "warning-row";
                }
            }
            return "";
        },
        async getDetail(val) {
            let result = await this.GetCheckTypeDetail({
                yclxid: val.JRJGLXID,
            });
            this.gridData = result.ResultValue;
            this.dialogTableVisible = true;
        },
    },
    mouted() {
        this.activeNames.push("1");
    },
};
</script>
<style scoped>
.expanded {
    transform: rotate(180deg);
}
</style>
<style>
/**设备运行状态样式**/

.crs-equipment-check-result {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 15px 25px;
    text-align: left;
    margin-top: 20px;
    border: none;
    border-top: 1px solid #c9c9c9;
}

/* 链接图标 */

.linkIcon {
    width: calc(100% - 20px);
    height: 28px;
    position: absolute;
    top: -20px;
}

.link-left {
    width: 10px;
    height: 28px;
    float: left;
    margin-left: 50px;
}

.link-right {
    width: 10px;
    height: 28px;
    float: right;
    margin-right: 50px;
}

.el-table .warning-row {
    color: #fd2b1c;
}

.el-table .success-row {
    color: green;
}

.el-table td,
.el-table th {
    padding: 6px 0;
}

.el-table th,
.el-table tr {
    background-color: #f6f5fa;
}

.el-table__empty-block {
    background-color: #f6f5fa;
}

/**设备运行状态样式end**/

.el-collapse-item__arrow {
    float: left;
}

.state_content {
    width: 62%;
    height: 210px;
    border-bottom: 1px solid #ccc;
    text-align: left;
}

.state_content div:first-child {
    font-weight: 600;
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.state_content div:nth-child(2) {
    padding: 10px;
    color: #353535;
}

.state_content div:nth-child(2) i {
    color: #7fdfb0;
}

.state_content div:nth-child(3) {
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
    padding: 10px;
    color: #353535;
}

.state_content div:nth-child(3) i {
    color: #de3923;
}

.state_content div:nth-child(4) {
    padding-left: 10px;
    color: #353535;
}

.state_content div:nth-child(4) i {
    color: #c0e2ef;
}

.er-bottom {
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 40px;
    padding: 0 25px;
    background: #e2e9f1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.er-ibord {
    line-height: 2;
    margin: 0 50px 0 0;
}

.er-status-icon {
    margin: 0 8px 10px 0;
}

.er-normal-text {
    color: #2ec290;
}

.er-special-text {
    color: #ff9066;
}

.er-normal-count {
    color: #333;
}

.er-normal-num {
    margin: 0 5px 0 10px;
    font-size: 20px;
    font-weight: bold;
}

.er-lasttime {
    position: absolute;
    right: 25px;
}

.etr-arrows {
    position: absolute;
    left: 100px;
    top: -10px;
    width: 20px;
    height: 20px;
    transform: rotate(45deg);
    border: 1px solid #c9c9c9;
    border-bottom: none;
    border-right: none;
    background: #eaece6;
}
</style>
